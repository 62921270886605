import fetch from '@/utils/fetch';

export function submitSurvey(data) {
	return fetch({
		url: '/survey/submitSurvey',
		method: 'post',
		data
	});
}

export function checkSurvey(data) {
	return fetch({
		url: '/survey/checkSurvey',
		method: 'post',
		data
	});
}