<template>
    <el-row :gutter="10" class="survey-row">
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="18">
            <div class="survey-container">
                <el-card class="box-card" v-if="!surveyDone" v-loading="loading">
                    <div slot="header" class="clearfix">
                        <span class="card-title"
                            >Chestionar evaluare comanda <span class="cel">{{ orderId }}</span> de la Partenerul
                            <span class="cel">{{ supplierName }}</span></span
                        >
                    </div>
                    <div class="body">
                        <div class="desc">
                            <div>
                                <strong>Iti multumim ca ai comandat la cel.ro.</strong> <br />
                                Din dorinta de a imbunatati calitatea serviciilor noastre, te rugam sa ne acorzi 2
                                minute in care sa evaluezi modul in care a decurs comanda de mai jos.
                            </div>
                            <p>
                                Chestionarul va expira automat dupa <strong>5 zile</strong>, deci grabeste-te si
                                spune-ne parerea ta. Nu dureaza mai mult de 2 minute :)
                            </p>
                            <p>
                                <strong
                                    >Pe scurt, alege raspunsul la intrebari tinand cont de urmatoarele: 1 = foarte
                                    nemultumit, 5 = foarte multumit</strong
                                >
                            </p>
                        </div>

                        <div class="questions">
                            <div class="title"></div>
                            <div class="question" v-for="(q, i) in questions" :key="i">
                                <strong>{{ q.question }}</strong>
                                <div class="radio">
                                    <el-radio-group v-model="q.answer">
                                        <p v-for="(a, i) in q.answers" :key="i">
                                            <el-radio :label="a.id">{{ a.label }}</el-radio>
                                        </p>
                                    </el-radio-group>
                                    <div class="error" v-if="q.error">Nu ai selectat un raspuns valid</div>
                                </div>
                            </div>
                            <div class="textarea-feedback">
                                <strong
                                    >Daca doriti sa abordati o problema referitoare la comanda care nu a fost cuprinsa
                                    in chestionar, va rugam sa completati casuta de mai jos:</strong
                                >
                                <el-input
                                    type="textarea"
                                    :autosize="{ minRows: 10, maxRows: 20 }"
                                    class="radio"
                                    v-model="textArea"
                                >
                                </el-input>
                            </div>
                        </div>
                    </div>
                    <el-button type="primary" class="fullWidth" @click="sendFeedback">Trimite</el-button>
                </el-card>
                <el-card class="box-card" v-else-if="surveyDone">
                    <div class="done-icon">
                        <svg-icon icon-class="favicon" />
                    </div>
                    <div class="done-text">
                        Chestionarul a fost trimis cu succes. Multumim pentru timpul acordat! Fiecare feedback ne ajuta
                        sa imbunatatim experienta clientilor nostri!
                    </div>
                </el-card>
            </div>
        </el-col>
    </el-row>
</template>

<script>
import { submitSurvey, checkSurvey } from '@/api/survey';
export default {
    props: ['id'],
    metaInfo() {
        return {
            meta: [{ name: 'robots', content: 'noindex' }]
        };
    },
    data() {
        return {
            textArea: '',
            questions: [
                {
                    id: 'question_1',
                    question:
                        'Pe baza experientei tale cu CEL.ro, care este probabilitatea de a ne recomanda celor dragi?',
                    answer: '',
                    answers: [
                        {
                            id: 5,
                            label: '5 (Cu siguranta as recomanda)'
                        },
                        {
                            id: 4,
                            label: '4 (As recomanda)'
                        },
                        {
                            id: 3,
                            label: '3 (Nu sunt sigur daca as recomanda)'
                        },
                        {
                            id: 2,
                            label: '2 (Nu as recomanda)'
                        },
                        {
                            id: 1,
                            label: '1 (Cu siguranta nu as recomanda)'
                        }
                    ],
                    error: false
                },
                {
                    id: 'question_2',
                    question: 'Cum apreciati durata de timp necesară furnizarii produselor din comanda?',
                    answer: '',
                    answers: [
                        {
                            id: 5,
                            label: '5 (Foarte multumit)'
                        },
                        {
                            id: 4,
                            label: '4 (Multumit)'
                        },
                        {
                            id: 3,
                            label: '3 (Pasiv)'
                        },
                        {
                            id: 2,
                            label: '2 (Nemultumit)'
                        },
                        {
                            id: 1,
                            label: '1 (Foarte nemultumit)'
                        }
                    ],
                    error: false
                },
                {
                    id: 'question_3',
                    question: 'Cum apreciati calitatea produselor primite?',
                    answer: '',
                    answers: [
                        {
                            id: 5,
                            label: '5 (Foarte multumit)'
                        },
                        {
                            id: 4,
                            label: '4 (Multumit)'
                        },
                        {
                            id: 3,
                            label: '3 (Pasiv)'
                        },
                        {
                            id: 2,
                            label: '2 (Nemultumit)'
                        },
                        {
                            id: 1,
                            label: '1 (Foarte nemultumit)'
                        }
                    ],
                    error: false
                },
                {
                    id: 'question_4',
                    question: 'Cat de usoara a fost comunicarea cu Partenerul Marketplace si CEL.ro?',
                    answer: '',
                    answers: [
                        {
                            id: 5,
                            label: '5 (Foarte usoara)'
                        },
                        {
                            id: 4,
                            label: '4 (Usoara)'
                        },
                        {
                            id: 3,
                            label: '3 (Oarecum usoara)'
                        },
                        {
                            id: 2,
                            label: '2 (Dificila)'
                        },
                        {
                            id: 1,
                            label: '1 (Foarte dificila)'
                        }
                    ],
                    error: false
                },
                {
                    id: 'question_5',
                    question: 'Cum calificati atitudinea generala a Partenerului Marketplace',
                    answer: '',
                    answers: [
                        {
                            id: 5,
                            label: '5 (Foarte profesionista)'
                        },
                        {
                            id: 4,
                            label: '4 (Profesionista)'
                        },
                        {
                            id: 3,
                            label: '3 (Pasiva)'
                        },
                        {
                            id: 2,
                            label: '2 (Neprofesionista)'
                        },
                        {
                            id: 1,
                            label: '1 (Deloc profesionista)'
                        }
                    ],
                    error: false
                }
            ],
            surveyDone: false,
            supplierName: '',
            orderId: '',
            loading: false
        };
    },
    methods: {
        sendFeedback() {
            if (this.validate()) return false;
            const params = {
                survey_id: this.id,
                answers: []
            };

            if (this.textArea !== '') {
                params.extra_details = this.textArea.trim();
            }

            for (const n in this.questions) {
                if (this.questions[n].answer !== '') {
                    params.answers.push({
                        id: this.questions[n].id,
                        answer: this.questions[n].answer
                    });
                }
            }
            this.loading = true;
            submitSurvey(params).then((res) => {
                this.loading = false;
                if (typeof res.message === 'undefined' || typeof res.message.done === 'undefined') {
                    return false;
                } else {
                    this.ready = false;
                    this.surveyDone = true;
                }
            });
        },
        validate() {
            let errors = false;
            for (const n in this.questions) {
                if (this.questions[n].answer === '') {
                    this.questions[n].error = true;
                    errors = true;
                } else this.questions[n].error = false;
            }
            return errors;
        },
        checkSurvey() {
            this.loading = true;
            checkSurvey({
                survey_id: this.id
            }).then((res) => {
                this.loading = false;
                try {
                    if (typeof res.message === 'undefined' || typeof res.message.supplier === 'undefined') {
                        this.$router.push('/login').catch(() => {});
                        return false;
                    } else {
                        this.ready = true;
                        this.surveyDone = false;
                        this.supplierName = res.message.supplier;
                        this.orderId = res.message.orders_id;
                    }
                } catch (e) {
                    this.$router.push('/login').catch(() => {});
                }
            });
        }
    },
    computed: {
        reactiveSupplierName() {
            return this.supplierName;
        }
    },
    created() {
        this.checkSurvey();
    }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.survey-row {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.question {
    margin-bottom: 50px;
}

div.error {
    color: red;
    font-size: 13px;
}

.ph {
    font-size: 12px;
    font-weight: 500;
}

.cel {
    color: #ff601d;
}

.card-title {
    font-size: 18px;
}

.done-icon {
    font-size: 150px;
    text-align: center;
    color: #67c23a;
}

.done-text {
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    padding: 25px;
}

.radio {
    margin: 10px 0;

    p {
        margin: 10px 0;
    }
}

.textarea-feedback {
    margin-bottom: 20px;
}
</style>
